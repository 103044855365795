<template>
    <b-card title="Jumlah Produksi Produk SmartFarm">
        <app-echart-bar :option-data="option" />
    </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
    components: {
        BCard,
        AppEchartBar,
    },
    data() {
        return {
            option: {
                xAxis: [
                    {
                        type: "category",
                        data: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "Mei",
                            "Jun",
                            "Jul",
                            "Ags",
                            "Sep",
                            "Okt",
                            "Nov",
                            "Des",
                        ],
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        splitLine: { show: false },
                    },
                ],
                grid: {
                    left: "40px",
                    right: "30px",
                    bottom: "30px",
                },
                series: [
                    // {
                    //     name: "Yoghurt",
                    //     type: "bar",
                    //     stack: "advertising",
                    //     data: [
                    //         220, 232, 201, 334, 290, 230, 220, 360, 80, 130,
                    //         310, 460, 110, 80,
                    //     ],
                    //     barMaxWidth: "20%",
                    //     barMinWidth: "10px",
                    // },
                    // {
                    //     name: "Susu Strawberry",
                    //     type: "bar",
                    //     stack: "advertising",
                    //     data: [
                    //         220, 232, 201, 234, 190, 130, 210, 163, 105, 120,
                    //         220, 102, 150, 130,
                    //     ],
                    //     barMaxWidth: "20%",
                    //     barMinWidth: "10px",
                    // },
                    // {
                    //     name: "Susu Melon",
                    //     type: "bar",
                    //     stack: "advertising",
                    //     data: [
                    //         220, 232, 201, 234, 190, 130, 210, 163, 105, 120,
                    //         220, 102, 150, 130,
                    //     ],
                    //     barMaxWidth: "20%",
                    //     barMinWidth: "10px",
                    // },
                    // {
                    //     name: "Susu Plain",
                    //     type: "bar",
                    //     stack: "advertising",
                    //     data: [
                    //         220, 232, 201, 234, 190, 130, 210, 163, 105, 120,
                    //         220, 102, 150, 130,
                    //     ],
                    //     barMaxWidth: "20%",
                    //     barMinWidth: "10px",
                    // },
                ],
            },
        };
    },
    async created() {
        await this.$http
                .get("dashboards")
                .then(async (response) => {
                console.log('db');
                let rawMilks = response.data.data.rawMilksMonthlyProduction[0];
                this.option.series.push(
                    {
                        name: "Susu Mentah",
                        type: "bar",    
                        stack: "advertising",
                        data: [
                            rawMilks.jan, rawMilks.feb, rawMilks.mar, rawMilks.apr, rawMilks.may, rawMilks.jun, rawMilks.jul, rawMilks.ags, rawMilks.okt,
                            rawMilks.sep, rawMilks.nov, rawMilks.des
                        ],
                        barMaxWidth: "20%",
                        barMinWidth: "10px",
                    }
                );


                let products = response.data.data.productionMilksMonthly;

                products.forEach((product) => {
                    
                    this.option.series.push(
                        {
                            name: product.name,
                            type: "bar",
                            stack: "advertising",
                            data: [
                                product.volume[0].jan, product.volume[0].feb, product.volume[0].mar, product.volume[0].apr, product.volume[0].may, product.volume[0].jun, product.volume[0].jul, product.volume[0].ags, product.volume[0].okt,
                                product.volume[0].sep, product.volume[0].nov, product.volume[0].des
                            ],
                            barMaxWidth: "20%",
                            barMinWidth: "10px",
                        }
                    );
                });

                
            });
    }
};
</script>
