<template>
    <b-card class="earnings-card">
        <b-row>
            <b-col cols="8">
                <b-card-title class="mb-1"> Produksi Hari Ini </b-card-title>
            </b-col>
            <b-col cols="4">
                <b-card-title class="mb-1">
                    <b-form-datepicker
                        v-model="value"
                        show-decade-nav
                        button-only
                        right
                        button-variant="primary"
                        @context="getTodayProduction"
                    />
                </b-card-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <div class="font-small-3">
                            Produksi
                            <!-- {{ value }} -->
                        </div>
                        <h5 class="mb-1">
                            <!-- 7250 ml -->
                            {{ data.total_produksi + " ml"}} 
                        </h5>
                    </b-col>
                    <b-col cols="6">
                        <div class="font-small-3">
                            Dari
                            <!-- {{ data.label[1] }} -->
                        </div>
                        <h5 class="mb-1">
                            <!-- 37 Kambing -->
                            {{ data.total_kambing + " Kambing"}}
                            <!-- {{ data.series[1] }} -->
                        </h5>
                    </b-col>
                </b-row>
               
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BButton,
    BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
    directives: {
        Ripple,
    },
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
        BButton,
        BFormDatepicker,
    },

    methods: {
        getTodayProduction(ctx) {
            this.$http
                .get(
                    `/milk/raw/day?start_date=${ctx.selectedYMD}&end_date=${ctx.selectedYMD}`
                )
                .then((response) => {
                    this.data = response.data.data;
                });
        },
    },
    data: () => ({
        data: {},
        value: new Date(),
    }),
    created() {
        this.getTodayProduction(this.value);
    },
};
</script>
