<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height p-0 m-0" md="12" sm="12">
            <b-col xl="8" md="12" sm="12" class="m-0 p-0">
                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="6" md="12" sm="12">
                        <milk-earnings-chart
                            :data="data.total"
                            :titleChart="titleProduction"
                            :resultChart="data.total.result"
                        />
                    </b-col>
                    <!-- <b-col xl="6" md="6" sm="12">
                        <total-produksi />
                    </b-col> -->

                    <b-col xl="6" md="12" sm="12">
                        <milk-earnings-chart
                            :data="data.average"
                            :titleChart="titleAverage"
                            :resultChart="data.average.result"
                        />
                    </b-col>
                </b-row>

                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12" class="m-0 pl-1 pr-1">
                        <echart-bar />
                    </b-col>
                </b-row>

                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12" class="m-0 pl-1 pr-1">
                        <table-goat-mating />
                    </b-col>
                </b-row>

                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12" class="m-0 pl-1 pr-1">
                        <animal-type />
                    </b-col>
                </b-row>
                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12" class="m-0 pl-1 pr-1">
                        <animal-pen />
                    </b-col>
                </b-row>
            </b-col>

            <b-col xl="4" md="12" sm="12" class="m-0 p-0">
                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                        <today-production />
                        <!-- <milk-earnings-chart
                            :data="data.earningsChart"
                            :titleChart="titleToday"
                            :resultChart="10"
                        /> -->
                    </b-col>
                </b-row>

                <b-row class="m-0 m-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                        <card-product-stock
                            :data="data.stokSusu"
                        />
                    </b-col>
                </b-row>
                <b-row class="m-0 p-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                        <card-advance-employee-task
                            :data="data.goatsRankInRawMilkProduction"
                            :title="titleGoatRank"
                        />
                    </b-col>
                </b-row>

                <b-row class="m-0 m-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                    </b-col>
                </b-row>

                <b-row class="m-0 m-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                        <card-new-born
                            :data="data.animalPregnant"
                            :title="titleAnimalPregnant"
                        />
                    </b-col>
                </b-row>

                <b-row class="m-0 m-0" md="12" sm="12">
                    <b-col xl="12" md="12" sm="12">
                        <card-new-born
                            :data="data.animalNewBorn"
                            :title="titleAnimalNewBorn"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-modal
            id="modal-alert-notification"
            content-class="shadow"
            title="Info SmartFarm"
            no-fade
            v-model="showModalNotificationInfo"
            >

            <b-img :src="require('@/assets/images/web/info-notification.png')" fluid alt="Responsive image"></b-img>
            
            <b-alert
                show
                variant="warning"
            >
                <div class="alert-body">
                    SmartFarm membutuhkan izin Anda untuk mengirimkan notifikasi terkait SmartFarm,
                    Mohon klik Izinkan/"Allow" saat permintaan izin tampil.
                </div>
            </b-alert>
            <template #modal-footer>
                <div class="w-100">
                  <b-button 
                    class="float-right" 
                    size="md" 
                    variant="primary" 
                    @click="getNotificationPermission()"
                    
                    >
                    Mengerti
                  </b-button>
                </div>
              </template>
    </b-modal>
    </section>
</template>

<script>
//component
import { BImg, BButton, BModal, BAlert, BCard, BCardText, BRow, BCol } from "bootstrap-vue";
import MilkEarningsChart from "./MilkEarningsChart.vue";
import EchartBar from "./EchartBar.vue";
import CardAdvanceEmployeeTask from "./CardAdvanceEmployeeTask.vue";
import CardProductStock from "./CardProductStock.vue";
import CardFeedStock from "./CardFeedStock.vue";
import CardNewBorn from "./CardNewBorn.vue";
import TableGoatMating from "./TableGoatMating.vue";
import AnimalType from "./AnimalType.vue";
import AnimalPen from "./AnimalPen.vue";
import TodayProduction from "./TodayProduction.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getMessaging, getToken } from "firebase/messaging"
// import { getUserData } from '@/auth/utils'

export default {
    components: {
        BImg,
        BButton,
        BModal, 
        BAlert,
        BRow,
        BCol,

        BCard,
        BCardText,
        MilkEarningsChart,
        EchartBar,
        CardAdvanceEmployeeTask,
        TableGoatMating,

        CardFeedStock,
        CardNewBorn,
        CardProductStock,
        AnimalType,
        AnimalPen,

        TodayProduction,
    },
    methods: {
        validateLabel(value) {
            return value > 0 ? "Naik" : "Turun";
        },
        getNotificationPermission(){
            this.showModalNotificationInfo = !this.showModalNotificationInfo

            Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    const messaging = getMessaging();
                
                    getToken(messaging, { vapidKey: `${process.env.VUE_APP_VAPID_KEY}` })
                    .then((currentToken) => {
                        if (currentToken) {
                            // get user data
                            // if token not exist send token to server
                            this.$http.post('/notifications/fcm', {
                                fcm_token: currentToken
                            }).then((response) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                    title: 'Info Smartfarm',
                                    icon: 'InfoIcon',
                                    text: 'Notifikasi berhasil diizinkan',
                                    variant: 'info',
                                    },
                                },
                                {
                                    position: 'top-center',
                                    timeout: 8000,
                                })
                                  
                            }).catch((error) => {
                                console.log(error)
                            });
                        } else {
                            // Show permission request UI
                            console.log('No registration token available. Request permission to generate one.');
                        }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                // ...
                });
            }
        })
        }
    },
    data() {
        return {
            data: {
                showModalNotificationInfo: false,
                total: {
                    label: [],
                    series: [],
                    result: "",
                    resultLabel: "",
                },
                average: {
                    label: [],
                    series: [],
                    result: "",
                    resultLabel: "",
                },
                produksi: {},
                today_production: {},
                earningsChart: {
                    label: [],
                    series: [],
                    result: "",
                    resultLabel: "",
                },
                pregnants: [{}],
                goatsRankInRawMilkProduction: [],
                stokSusu: [],
                kandang: [{}],
                animalType: [{}],
                animalNewBorn: [],
                animalPregnant: [{}],
            },
            resultLabelAverage: "",
            resultLabelTotal: "",
            titleStokPakan: "Stok Pakan",
            titleAnimalNewBorn: "List Kambing Baru Lahir",
            titleAnimalPregnant: "List Kambing Bunting",
            titleGoatRank: "Rank Perolehan Susu",
            titleProduction: "Total Produksi",
            titleAverage: "Rata-rata Produksi",
            titleToday: "Produksi Hari Ini",
            
        };
    },
    async created() {
        
        if(Notification.permission === 'default' || Notification.permission === 'denied'){ 
            this.showModalNotificationInfo = !this.showModalNotificationInfo
        }
        
        
        // dashboard start
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth();
        let nextMonth = currentMonth + 1;
        let yearWithMonth = currentYear + '-0' + currentMonth;
        let yearWithNextMonth = currentYear + '-0' + nextMonth;
        await this.$http
            .get("/milk/raw/month?start_year=" + yearWithMonth + "&end_year=" + yearWithNextMonth)
            .then(async (response) => {
                this.data.average.label = response.data.data.Year;
                response.data.data.MilkVolume.map((volume) => {
                    volume == null
                        ? this.data.average.series.push(0)
                        : this.data.average.series.push(parseInt(volume));
                });

                const result = this.data.average.series[1] - this.data.average.series[0];
                
                this.data.average.resultLabel = this.validateLabel(
                    result
                );
                this.data.average.result = `${result}`;
                // this.data.average.result = result;
                // this.resultLabelAverage = this.validateLabel(result);
                // console.log(this.data.average.label);
            });

        await this.$http
            .get("/milk/raw/year?start_year=" + yearWithMonth + "&end_year=" + yearWithNextMonth)
            .then((response) => {
                this.data.total.label = response.data.data.Year;
                response.data.data.MilkVolume.map((volume) => {
                    volume == null
                        ? this.data.total.series.push(0)
                        : this.data.total.series.push(parseInt(volume));
                });

                const diff =
                    this.data.total.series[1] - this.data.total.series[0];
                const result =
                    (diff * 100) / parseInt(response.data.data.Year[0]);
                this.data.total.resultLabel = this.validateLabel(
                    parseInt(result)
                );
                this.data.total.result = `${parseInt(result)}%`;
                this.data.total.result = result;
                // this.resultLabelTotal = this.validateLabel(result);
                // console.log(this.resultLabelTotal);
            });

        // dashboard finish


        let today = new Date().toLocaleDateString();

        await this.$http
            .get("/milk/raw/day?start_date="+today+"&end_date="+today)
            .then((response) => {
                console.log(response.data.data);
                this.data.today_production.raw_milks_total = response.data.data.total_produksi;
                this.data.today_production.goat_total = response.data.data.total_kambing;

            });

        await this.$http
            .get("/milk/productions/categories")
            .then((response) => {
                // console.log(response.data.data);
                this.data.stokSusu = response.data.data;
            });
        this.$http.get("/milk/raw/rank").then((response) => {
            const data = response.data.data;
            // console.log(data);
            this.data.goatsRankInRawMilkProduction = response.data.data;
        });
        
        this.$http
            .get("/animals/AllAnimals?min_age=0&max_age=10")
            .then((response) => {
                // console.log(response.data.data);
                const { data } = response.data;
                let arr = [];
                data.forEach((goat) => {
                    arr.push({
                        name: goat.name,
                        desc: "Tgl-lahir : ",
                        total_volume: goat.birth_date,
                    });
                });
                this.data.animalNewBorn = arr;
            });
        // this.$http.get('/dashboards').then(response => {
        //   this.data = response.data.data
        //   console.log(response.data.data)
        //   this.data.goatsRankInRawMilkProduction = response.data.data.goatsRankInRawMilkProduction
        // })
        // this.$http.get('/milk/productions/group/by/category').then(response => {
        //   const data = response.data.data
        //   console.log(data)
        //   this.data.stokSusu = response.data.data
        // })
        // this.$http.get('/animals/group/by/type').then(response => {
        //   const data = response.data.data
        //   this.data.animalType = data
        // })
        // this.$http.get('/animals/group/by/pen').then(response => {
        //   const data = response.data.data
        //   this.data.kandang = data
        // })
        await this.$http.get("/animal-matings/", {params: {result: 'positive'}}).then((response) => {
              const data = response.data.data
              let arr = []
              data.forEach(goat => {
                  arr.push(
                    {
                      name: goat.betina,
                      desc: 'HPL : ',
                      total_volume: goat.hpl,
                      photo: goat.file_path,
                    }
                  )
                });
               this.data.animalPregnant = arr
        });
    },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
