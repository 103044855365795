<template>
    <b-card no-body class="card-employee-task">
        <b-card-header>
            <div class="d-flex justify-content-between align-items-center">
                <b-card-title> Stok Pakan </b-card-title>
            </div>
        </b-card-header>

        <!-- body -->
        <b-card-body>
            <div class="employee-task d-flex justify-content-between mr-0 pr-0">
                <div>
                    <div class="d-flex align-items-center">
                        <b-card-text class="h6 text-muted mr-4"
                            >Total</b-card-text
                        >
                        <b-card-text class="h6 text-muted">Dipakai</b-card-text>
                    </div>

                    <div class="d-flex align-items-center mb-1">
                        <h4 class="h4 font-weight-normal mr-5">
                            {{ currentStock }}
                        </h4>
                        <h4 class="h4 font-weight-normal">{{ stockOut }}</h4>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-card-text class="h6 text-muted mr-2"
                            >Terakhir diperbaruhi:
                            {{ moment(update_date).format("LL") }}</b-card-text
                        >
                        <b-card-text class="h6 text-muted mr-2"></b-card-text>
                    </div>
                </div>

                <div class="pl-0 ml-0">
                    <feather-icon size="28" icon="RefreshCcwIcon" />
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
// import moment from "moment";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        VueApexCharts,
    },
    data() {
        return {
            currentStock: null,
            stockOut: null,
            update_date: null,
        };
    },
    created() {
        this.$http.get("/feeds/stocks").then((response) => {
            const data = response.data.data[0];

            this.currentStock = data.feed_in_total;
            this.stockOut = data.feed_out_total;
            this.update_date = data.updated_at;
        });
    },
};
/* eslint-disable global-require */
</script>
