<template>
  <b-card title="Data Kambing Berdasarkan Jenis">
    <app-echart-bar
      :key="keyChart"
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import {ref } from '@vue/composition-api'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  async mounted() {
    await this.$http.get('animals/group/by/type').then(
        (response) => {
            const data  = response.data.data;

            this.option.xAxis = {
                type: 'category',
                data: data.label,
            }
            
            this.option.series = {
              type: 'bar',
              stack: 'advertising',
              data: data.total,
              barMaxWidth: '20%',
              barMinWidth: '10px',
          }
          this.keyChart += 1;
        }
    )
  },
  setup(){
    const blankChartData = {
      xAxis: null,
      yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
      grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
      series: null,
    }

    const option = ref(JSON.parse(JSON.stringify(blankChartData)))
    const keyChart = ref(0)
    return {
      option,
      keyChart,
    }
  },
}
</script>
