<template>
    <b-card class="earnings-card">
        <b-row>
            <b-col>
                <b-card-title class="mb-1">
                    {{ titleChart }}
                    <!-- {{ data }} -->
                </b-card-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-row>
                    <b-col cols="6">
                        <div class="font-small-3">
                            {{ moment(data.label[0]).format("MMMM")  }} 
                            <!-- {{ chartOptions }} -->
                        </div>
                        <h5 class="mb-1">
                            {{ data.series[0] + ' ml' }}
                        </h5>
                    </b-col>
                    <b-col cols="6">
                        <div class="font-small-3">
                            {{ moment(data.label[1]).format("MMMM") }} 
                        </div>
                        <h5 class="mb-1">
                            {{ data.series[1] + ' ml' }} 
                        </h5>
                    </b-col>
                </b-row>
                <b-card-text class="text-muted font-small-2">
                    <span class="font-weight-bolder">{{data.series[1] - data.series[0]}}</span
                    ><span> dari bulan sebelumnya.</span>
                    <p>{{ data.resultLabel }}</p>
                </b-card-text>
            </b-col>

            <b-col cols="6">
                <!-- chart -->
                <vue-apex-charts
                    height="100"
                    :options="earningsChart.chartOptions"
                    :series="data.series"
                />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
// import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
// import { onMounted, ref, toRefs } from "@vue/composition-api";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
        VueApexCharts: () => import("vue-apexcharts"),
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        titleChart: {
            type: String,
        },
        resultChart: {
            type: Number,
        },
    },
    data(props) {
        // console.log(props.data);
        return {
            // series: [250, 350],
            earningsChart: {
                chartOptions: {
                    chart: {
                        type: "donut",
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: { show: false },
                    comparedResult: [2, -3],
                    labels: [],
                    stroke: { width: 0 },
                    colors: [
                        $earningsStrokeColor2,
                        $earningsStrokeColor3,
                        $themeColors.success,
                    ],
                    grid: {
                        padding: {
                            right: -18,
                            bottom: -8,
                            left: -18,
                        },
                    },
                    plotOptions: {
                        pie: {
                            startAngle: 10,
                            donut: {
                                labels: {
                                    show: false,
                                    name: {
                                        offsetY: 15,
                                    },
                                    value: {
                                        offsetY: -15,
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)}`;
                                        },
                                    },
                                    total: {
                                        show: false,
                                        offsetY: 35,
                                        // label: `${
                                        //     parseInt(props.resultChart) < 0
                                        //         ? "Naik"
                                        //         : "Turun"
                                        // }`,
                                        label: "",
                                        formatter() {
                                            // console.log(props.resultChart);
                                            return ``;
                                        },
                                    },
                                },
                            },
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 1325,
                            options: {
                                chart: {
                                    height: 100,
                                },
                            },
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                        {
                            breakpoint: 1045,
                            options: {
                                chart: {
                                    height: 100,
                                },
                            },
                        },
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                    ],
                },
            },
        };
    },
};
</script>
