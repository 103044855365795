<template>
    <b-card no-body class="card-employee-task">
        <b-card-header>
            <div class="d-flex justify-content-between align-items-center">
                <b-card-title class="mr-1">Stok Produk SmartFarm</b-card-title>
                <!-- <span class="ml-2 pl-1">
                    <b-badge variant="dark">
                        Stok : {{ data.stock_remaining  }}
                    </b-badge>
                </span> -->
            </div>
        </b-card-header>

        <!-- body -->
        <b-card-body>
            <div
                v-for="product in data.product"
                :key="product.label"
                class="employee-task d-flex justify-content-between align-items-center"
            >
                <b-media no-body>
                    <b-media-aside class="mr-75">
                        <b-avatar
                            rounded
                            variant="white"
                            size="42"
                            :src="require('@/assets/images/icons/milk.svg')"
                        />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                        <h6 class="mb-0">
                            {{ product.category }}
                        </h6>
                        <small> Produk Olahan </small>
                    </b-media-body>
                </b-media>
                <div class="d-flex align-items-center">
                    <small class="text-muted mr-75"></small>

                    <b-badge variant="dark">
                        {{ product.volume_in_total }}
                    </b-badge>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BBadge,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
    components: {
        BCard,
        BBadge,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        VueApexCharts,
    },
    props: {
        data: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
            default: "",
        },
    },
    data(props) {
        // console.log(props.data);
        return {};
    },
};
/* eslint-disable global-require */
</script>
