<template>
  <b-card-code
    no-body
    title="Kondisi Ternak"
  >
    <b-table
      responsive="sm"
      :items="items"
    />

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    BTable,
  },
  data() {
    return {
      items: [
        {
          no: 1, nama_kambing: 'Kambing Betina 32', status: 'Bunting', 
        },
        {
          no: 2, nama_kambing: 'Kambing Betina 28', status: 'Bunting',
        },
        {
          no: 3, nama_kambing: 'Kambing Betina 20', status: 'Bunting',
        },
        {
          no: 4, nama_kambing: 'Kambing Betina 25', status: 'Pertumbuhan',
        },
        {
          no: 5, nama_kambing: 'Kambing Betina 66', status: 'Pertumbuhan', 
        },
      ],
      codeBasic,
    }
  },
}
</script>
